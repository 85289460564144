import React, { useState } from 'react';
import styled from 'styled-components';
import { useAsyncFn, useSessionStorage } from 'react-use';
import { use100vh } from 'react-div-100vh';

// Components
import { FabricProductPreview } from '../../components/product/preview';

const Page = styled.div`
  padding: 51px 0 0 0;

  @media (max-width: 960px) {
    padding: 30px 0;
  }

  min-height: calc(${props => props.height}px - 50px);
`;

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  color: #333333;
  min-height: calc(${props => props.height}px - 50px - 50px);

  & .recent-ids-container {
    /* font-size: 12px; */
    /* line-height: 16px; */
  }

  & .container {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;

    & .text-container {
      & p {
        margin: 0;
      }
    }

    & form {
      display: grid;
      grid-template-columns: 1fr 100px;
      grid-column-gap: 30px;

      & button {
        border: 1px solid #333333;

        display: block;

        font-size: 12px;
        line-height: 39px;
        letter-spacing: 0.02em;

        color: #333333;
        background-color: #fff;

        width: 100%;
        height: 40px;

        transition: 250ms background-color ease, 250ms color ease;
        text-transform: uppercase;
        font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
          'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue',
          'Lucida Grande', sans-serif;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #fff;
            background-color: #333333;
            border: 1px solid #333333;
          }
        }
      }

      & input {
        width: 100%;

        padding: 0;
        margin: 0;
        border: 0;

        color: #333333;
        background: transparent;

        border-bottom: 1px solid #333333;

        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.04em;

        font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
          'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue',
          'Lucida Grande', sans-serif;

        ::-webkit-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #333333;

          font-family: 'Garnett Light', system, -apple-system,
            '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI',
            'Helvetica Neue', 'Lucida Grande', sans-serif;
        }
        ::-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #333333;

          font-family: 'Garnett Light', system, -apple-system,
            '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI',
            'Helvetica Neue', 'Lucida Grande', sans-serif;
        }
        :-ms-input-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #333333;

          font-family: 'Garnett Light', system, -apple-system,
            '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI',
            'Helvetica Neue', 'Lucida Grande', sans-serif;
        }
        :-moz-placeholder {
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.02em;

          text-transform: uppercase;
          color: #333333;

          font-family: 'Garnett Light', system, -apple-system,
            '.SFNSText-Regular', 'San Francisco', 'Roboto', 'Segoe UI',
            'Helvetica Neue', 'Lucida Grande', sans-serif;
        }
      }
    }
  }
`;

const Product = () => {
  const height = use100vh();
  const [recentIds, setRecentIds] = useSessionStorage('recent-ids', []);
  const [productId, setProductId] = useState(null);

  const [productPreviewData, getProductPreviewData] = useAsyncFn(
    async handle => {
      const response = await fetch(`/.netlify/functions/get-product-preview`, {
        method: 'POST',
        body: JSON.stringify({
          handle: handle,
          id: handle,
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const result = await response.json();
      return result;
    },
    []
  );

  const findProduct = e => {
    e.preventDefault();

    if (productId !== null) {
      getProductPreviewData(`gid://shopify/Product/${productId}`);

      if (recentIds.length >= 1) {
        setRecentIds([productId, ...recentIds]);
      } else {
        setRecentIds([productId]);
      }
    }
  };

  if (
    productPreviewData.loading === false &&
    productPreviewData.value !== undefined
  ) {
    return <FabricProductPreview data={productPreviewData.value} />;
  } else {
    return (
      <Page height={height} className="news-gothic">
        <LoadingScreen height={height}>
          <div className="container">
            <div className="container">
              <form onSubmit={e => findProduct(e)}>
                <input
                  type="text"
                  onChange={e => setProductId(e.target.value)}
                  placeholder="Enter Product ID"
                />
                <button type="submit">Preview</button>
              </form>

              <div className="text-container">
                <br />
                <p>You can find the product ID by copying the number after</p>
                <p>'https://mourne-textiles.myshopify.com/admin/products/'</p>
                <p>in the browser address bar.</p>
                <br />
                <p>An example ID would be 6713106792611</p>
              </div>
            </div>

            {recentIds.length >= 1 && (
              <div className="recent-ids-container">
                <br />
                <br />

                <h4>Recent IDs</h4>

                <ol>
                  {recentIds
                    .filter((id, index) => index <= 2)
                    .map((id, index) => (
                      <li key={`single_recent_id_${index}`}>{id}</li>
                    ))}
                </ol>
              </div>
            )}
          </div>
        </LoadingScreen>
      </Page>
    );
  }
};

export default Product;
